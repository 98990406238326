<template>
  <div class="chartdiv" :class="refName" :ref="refName" :style="divStyle"></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import '@amcharts/amcharts4/charts'

import { makeDataProvider, mapHeaderAndData } from '../common.js'
export default {
  props: ['configuration', 'dataSource', 'divStyle'],
  watch: {
    configuration() {
      // this.refName = 'vue-amchart-' + Math.random().toString().substr(2)
      this.$forceUpdate()
      this.load()
    },
    refName() {
      this.load()
      //   console.log(nv, '|=================')
    }
  },
  data: () => ({
    refName: '',
    chartConfig: {}
  }),
  methods: {
    load() {
      this.chartConfig = JSON.parse(JSON.stringify(this.configuration))
      console.log('Chart to load ===>', this.dataSource)
      if (
        this.dataSource &&
        this.dataSource.headers &&
        this.dataSource.headers.length
      ) {
        if (this.dataSource.rows.length) {
          this.chartConfig.data = this.chartConfig.data = mapHeaderAndData(
            JSON.parse(JSON.stringify(this.dataSource.rows)),
            this.dataSource.headers
          )
        } else {
          this.chartConfig.data = makeDataProvider(this.dataSource)
        }
      }
      console.log(`[this.chartConfig.data]: `, this.chartConfig.data)
      //TODO: PUT KEY AT SECURE PALACE
      am4core.addLicense('CH198746946')
      am4core.options.commercialLicense = true
      am4core.createFromConfig(
        JSON.parse(JSON.stringify(this.chartConfig)),
        // this.$refs[this.refName]
        // 'chartdiv'
        this.refName
      )
      //   console.log(chart)
      //   console.log(`[  this.$refs[this.refName]]: `, this.$refs[this.refName])
      //   console.log(`[this.$refs]: `, this.$refs, this.refName)
      this.$forceUpdate()
    }
  },
  created() {
    // console.log(
    //   '%c chart-preview',
    //   'color: pink; background: black',
    //   this.configuration
    // )
    if (this.configuration) {
      this.refName = 'vue-amchart-' + Math.random().toString().substr(2)
      this.load()
    }
  }
}
</script>

<style scoped>
.chartdiv {
  width: 100%;
  height: 100%;
  /* height: 500px;
  width: 500px; */
  border: 2px solid #efefef;
  /* padding: 20px; */
  /* display: grid; */
}
</style>